import { findUsedVendorsForDeclaration } from "./findUsedVendorsForDeclaration.js";
/**
 * Checks, if a content which is currently blocked, can be unblocked through consent in the TCF specification.
 *
 * @param model The TCF consent model
 * @param requiredIds Parse `requiredIds` as it could look like this: `['755', 'p1', 'p3']` (technical limitation of `@devowl-wp/headless-content-blocker`
 *                    and the way how `getRequiredIds` for blockables work)
 * @returns
 */ function decideToUnblockTcfBlockedContent(model, gvl, requiredIds) {
    const { vendorConsents, vendorLegitimateInterests, purposeConsents, purposeLegitimateInterests } = model;
    const requiredVendors = [];
    const requiredPurposes = [];
    for (const requiredId of requiredIds){
        if (requiredId.startsWith("p")) {
            requiredPurposes.push(+requiredId.slice(1));
        } else {
            requiredVendors.push(+requiredId);
        }
    }
    return requiredVendors.every((vendorId)=>{
        // Check, if the vendor has consent in legitimate interest or consent
        const hasVendorLegitimateInterest = vendorLegitimateInterests.has(vendorId);
        const hasVendorConsent = vendorConsents.has(vendorId);
        const vendor = gvl.vendors[vendorId];
        if (!hasVendorLegitimateInterest && !hasVendorConsent) {
            // Neither legitimate interest nor consent given for that vendor
            return false;
        } else {
            // Check if the needed purposes are also checked for that vendor
            return requiredPurposes.every((purposeId)=>// Check if the purpose for that vendor is part of "Legitimate interest"
                findUsedVendorsForDeclaration(gvl, model, purposeId, "purposes", true).indexOf(vendor) > -1 ? hasVendorLegitimateInterest && purposeLegitimateInterests.has(purposeId) : findUsedVendorsForDeclaration(gvl, model, purposeId, "purposes", false).indexOf(vendor) > -1 ? hasVendorConsent && purposeConsents.has(purposeId) : false);
        }
    });
}
export { decideToUnblockTcfBlockedContent };
